<template>
    <v-container>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
            <v-row>
                {{ text }}
                <v-spacer></v-spacer>
                <v-btn
                dark
                icon
                @click="snackbar = false"
                >
                <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-row>
        </v-snackbar>
        <v-form v-model="validForm" ref="contractForm">
            <h1 class="blueDark--text mb-5">{{$route.name === 'addContract' ? 'Add Contract' : 'Edit Contract'}}</h1>
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="contract.order" outlined label="Order" type="number" min="1" placeholder="Enter Order" :rules="[v => !!v || 'Item is required']" color="blue"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="contract.contract_number" outlined label="Contract Number" placeholder="Enter Contract Number" color="blue"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="contract.name" outlined label="Name" placeholder="Enter Name" :rules="[v => !!v || 'Item is required']" color="blue"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-select :rules="[v => !!v || 'Item is required']" :items="regions" label="Group" placeholder="Select Group" v-model="contract.region_id" item-text="name" item-value="id" outlined color="blue"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <!-- <v-select :rules="[v => v.length > 0 || 'Item is required']" :items="airlines" label="Airlines" placeholder="Search Airlines" v-model="contract.airline_codes" item-text="name" item-value="code" multiple outlined color="blue">
                        <template v-slot:selection="{ item, i }">
                            <v-chip close close-icon="mdi-close-circle" @click:close="contract.airline_codes.splice(i, 1)">
                                <span>{{ item.name.split(' ')[0] }} ({{ item.code }}) </span>
                            </v-chip>
                        </template>
                    </v-select> -->
                    <v-autocomplete
                    v-model="contract.airline_codes"
                    :rules="[v => v.length > 0 || 'Item is required']"
                    :items="airlines"
                    outlined
                    chips
                    color="blue"
                    label="Search Airlines"
                    item-text="name"
                    item-value="code"
                    multiple
                    >
                    <template v-slot:selection="{ item, i }">
                      <v-chip close close-icon="mdi-close-circle" @click:close="contract.airline_codes.splice(i, 1)">
                        <span>{{ item.name.split(' ')[0] }} ({{ item.code }}) </span>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-menu nudge-top="30" v-model="startDateMenu"  :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined :rules="[v => !!v || 'Item is required']" v-model="contract.start_date" label="Ticketing Start Date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="blue"></v-text-field>
                        </template>
                        <v-date-picker
                        color="blueDark"
                        v-model="contract.start_date"
                        @input="startDateMenu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-menu v-model="endDateMenu"  :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined :rules="[v => !!v || 'Item is required']" v-model="contract.end_date" label="Ticketing end Date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="blue"></v-text-field>
                        </template>
                        <v-date-picker
                        :disabled="!contract.start_date"
                        color="blueDark"
                        v-model="contract.end_date"
                        :min="contract.start_date || new Date().toISOString()"
                        @input="endDateMenu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                  v-model="contract.wb"
                  label="WB"
                  color="blue"
                  ></v-checkbox>
                  <p>Terms & Conditions</p>
                  <ckeditor editorUrl="https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js" v-model="contract.details"></ckeditor>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-hover v-slot="{ hover }">
                        <v-btn tile class="px-15 py-5 my-5" :color="hover ? 'blueDark' : 'orange'" dark @click="saveContract">save</v-btn>
                    </v-hover>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { contractAirlines, contractDetails, storeContract, updateContract, contractRegions, headers } from '../../links'
// import { htmlToText } from 'html-to-text'

export default {
  data () {
    return {
      regions: [],
      airlines: [],
      contract: {
        order: 1,
        name: '',
        start_date: '',
        end_date: '',
        airline_codes: [],
        contract_number: '',
        region_id: null,
        details: '',
        wb: false
      },
      startDateMenu: false,
      endDateMenu: false,
      snackbar: false,
      color: '',
      text: '',
      validForm: true,
      saveBtnLoad: false,
      loadingContract: false
    }
  },
  methods: {
    saveContract () {
      this.$refs.contractForm.validate()
      if (this.validForm && this.contract.details) {
        this.contract.details = this.contract.details.replaceAll(/"/g, "'")
        this.saveBtnLoad = true
        const endpoint = this.$route.name === 'editContract' ? updateContract(this.$route.params.id) : storeContract
        if (this.$route.name === 'editContract') {
          this.$http.put(endpoint, this.contract, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
            this.snackbar = true
            this.color = 'success'
            this.text = 'Contract is updated successfully'
          }).catch(err => {
            this.snackbar = true
            this.color = err.data.status === 200 ? 'success' : 'error'
            this.text = err.data.message
          }).finally(() => {
            this.saveBtnLoad = false
          })
        } else {
          this.$http.post(endpoint, this.contract, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
            this.snackbar = true
            this.color = 'success'
            this.text = 'Contract is added successfully'
            this.$refs.contractForm.reset()
            this.contract.details = 0
          }).catch(err => {
            this.snackbar = true
            this.color = err.data.status === 200 ? 'success' : 'error'
            this.text = err.data.message
            if (err.data.status === 200) {
              this.$refs.contractForm.reset()
              this.contract.details = 0
            }
          }).finally(() => {
            this.saveBtnLoad = false
          })
        }
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
      }
    }
  },
  created () {
    this.$http.get(contractAirlines, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      this.airlines = response.body.data
    }).catch(err => {
      console.log(err)
    })
    this.$http.get(contractRegions, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      this.regions = response.body.data
    }).catch(err => {
      console.log(err)
    })
    if (this.$route.name === 'editContract') {
      this.loadingContract = true
      this.$http.get(contractDetails(this.$route.params.id), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        this.contract.order = response.body.data.order
        this.contract.name = response.body.data.name
        this.contract.start_date = response.body.data.startDate
        this.contract.end_date = response.body.data.endDate
        this.contract.airline_codes = response.body.data.airlineCodes.map(item => item.airline_code)
        this.contract.region_id = response.body.data.region.id
        this.contract.details = response.body.data.details
        this.contract.wb = response.body.data.wb
        this.contract.contract_number = response.body.data.contract_number
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loadingContract = false
      })
    }
  }
}
</script>
